<template>
  <ul class="info">
    <li v-for="(item, index) in infoList" :key="index">
      <p class="vs">{{ item.version }} :</p>
      <p class="if">{{ item.information }}</p>
      <p class="tm">{{ item.time }}</p>
      <p></p>
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      infoList: [
        {
          version: "1.0.0",
          information: "推出秀兵证生成网站基础版(包括普通版,签名版)",
          time: "2022/10/24 16:07",
        },
        {
          version: "1.0.1",
          information:
            "更新秀兵证版本(更新包括纸质版,海克斯版,JDG联名版,RNG联名版),并推出备用地址 smfans.pages.dev",
          time: "2022/10/25 00:14",
        },
        {
          version: "1.1.0",
          information:
            "增加小签名位置微调功能,改正位置偏移错误(在此特别感谢贴吧用户:青岛第一深禽指出错误)",
          time: "2022/10/26 00:32",
        },
        {
          version: "1.1.1",
          information:
            "连接数据库,记录秀兵证生成数目,修改部分bug",
          time: "2022/10/30 22:25",
        },
      ],
    };
  },
};
</script>

<style scoped lang="less">
.info {
  width: 1000px;
  margin: 200px auto;
  padding: 0;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 3px 0;
    border-bottom: 1px solid #dcdfe6;
    .vs {
      padding: 0 20px;
        font-size: 16px;
        font-weight: 800;
    }
    .if {
      flex: 3;
    }
    .tm {
      padding: 0 20px;
      font-size: 12px;
      color: gray;
    }
  }
}
</style>
